import React from "react";
import CustomButton from "../CustomButton";

const Boxes = ({ data, handleBoxSelection }) => {
    const { randomizedBoxesArr } = data;

    let theContent = randomizedBoxesArr.map((card, index) => {
        const { color, isShown, isDisabled } = card;
        const backgroundColor = !!(isShown) ? color : "limegreen";

        const styles = {
            backgroundColor: backgroundColor,
            width: "100px",
            height: "100px",
            padding: "2em",
            margin: "1em"
        };

        return (
            <CustomButton
                key={ index }
                id={ index }
                title={ backgroundColor }
                className="card"
                style={{ ...styles }}
                onClick={ handleBoxSelection }

                disabled={ isDisabled }

                 
            />
        );
    });

    return (
        <>
            { theContent }
        </>
    );
};

export default Boxes