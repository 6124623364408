import React from "react";

const CustomButton = ({
    id,
    title,
    onClick, 
    className = "", 
    style = {},

    content,
    disabled = false
}) => {
    return(
        <>
            <button
                id={ id }
                title={ title }
                onClick={ onClick }
                className={ className }
                disabled={ disabled }
                style={ style }
            >
                <span dangerouslySetInnerHTML={{ __html: content }} />
            </button>
        </>
    );
}

export default CustomButton;