import React from "react";
import { NavLink } from "react-router-dom";
import { FiHome, FiTwitter } from "react-icons/fi";

const Header = ({ pageTitle }) => {
    const activeStyle = {
        color: "green", 
        padding: "0.5em 2em",
        display: "inline-block"
    };

    const socialStyle = {
        padding: "0.5em"
    };


    return (
        <>
            <header className="app__header">
                <div className="header__header">
                    <div className="header__menu__nav">
                        <NavLink activeStyle={activeStyle} to="/"><FiHome /></NavLink>
                    </div>

                    <div className="app__title">
                        react-sipofwater
                    </div>
                    
                    <div className="header__menu__social">
                        <a
                            href="https://twitter.com/sipofwater"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={socialStyle}
                        >
                            <FiTwitter />
                        </a>
                        <a
                            href="https://twitter.com/sipofwater"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={socialStyle}
                        >
                            <FiTwitter />
                        </a>
                        <a
                            href="https://twitter.com/sipofwater"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={socialStyle}
                        >
                            <FiTwitter />
                        </a>
                    </div>
                </div>
                <div>
                    <h1 className="header__title">{ pageTitle }</h1>
                </div>
            </header>
        </>
    );
}

export default Header;