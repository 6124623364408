export const generateRandomInteger = (max, min = 1) => {
    return (Math.random() * (max - min) + min).toFixed(0);
}

export const randomizeArray = (colorsPoolArr) => {
    console.log("randomizeArray: colorsPoolArr()");
    console.log(colorsPoolArr);

    // https://gist.github.com/guilhermepontes/17ae0cc71fa2b13ea8c20c94c5c35dc4 ::
    // var shuffled = array.map((a) => [Math.random(),a]).sort((a,b) => a[0]-b[0]).map((a) => a[1]);
    const randomizedBoxesArr = [...Array(16).keys()].map((a) => [Math.random(),a]).sort((a,b) => a[0]-b[0]).map((a) => a[1]);

    const randomizedBoxesColoredArr = randomizedBoxesArr.map(function(num) {
        const coloredBoxObj = {
            color: colorsPoolArr[num],
            isShown: false,
            isMatched: false,
            isDisabled: false
        };
        return coloredBoxObj;
    });

    //this.setState({ randomizedBoxesArr: randomizedBoxesColoredArr });
    return randomizedBoxesColoredArr;
}


