import React from "react";
import { HashRouter, Switch, Route } from "react-router-dom";

import '../index.css';
import HomePage from "../components/HomePage";
import MemoryGame from "../components/memory/MemoryGame";
import RandomBoxes from "../components/randomboxes/RandomBoxes";
import GuessTheFlag from "../components/guesstheflag/GuessTheFlag";

const AppRouter = () => (
    <HashRouter>
        <Switch>
            <Route exact path="/" component={ HomePage } />
            <Route path="/memory" component={ MemoryGame } />
            <Route path="/flags" component={ GuessTheFlag } />
            <Route path="/randomboxes" component={ RandomBoxes } />
        </Switch>
    </HashRouter>
);

export default AppRouter;