import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
const { projects, ideas } = require("../config.json");

const HomePage = () => {
    const pageTitle = "Welcome Home";
    const generateProjectList = projects.map((project) => 
        <li key={project.title}>
            <Link to={project.slug}>
                {project.title}
            </Link>
        </li>
    );
    const generateIdeastList = ideas.map((idea) => 
        <li key={idea}>
            {idea}
        </li>
    );

    return (
        <div className="App">
            <Header pageTitle={pageTitle} />

            <div className="main__content__container">
                <div>
                    <h1>Welcome to my React.js Portfolio!</h1>

                    <h3>Click on an App to get started!</h3>

                    <h4>My goal is to create 100 "standalone" apps in this portfolio.</h4>
                </div>

                <h3>Projects in progress/done:</h3>
                <ol>
                    { generateProjectList }
                </ol>

                <h3>Ideas for next projects:</h3>
                <ol>
                    { generateIdeastList }
                </ol>
            </div>
        </div>
    );
}

export default HomePage;