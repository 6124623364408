import React, { useState, useEffect, useContext } from 'react';
import "./RandomBoxes.css";
import Header from "../Header";
import Boxes from "./Boxes";
import { generateRandomInteger } from "../helpers";
import { BoxesContextProvider, BoxesContext } from "./boxes-context";

export default function RandomBoxes() {

    const [state, dispatch] = useContext(BoxesContext);

    const { colorPool, boxes } = BoxesContext;
    console.log("WTF:");
    console.log(boxes);

//    const colorPool = state.colorPool;
//    const boxes = state.boxes;


//    const { colorPool, boxes } = state;


    useEffect(() => {
        initializeBoxes();
/*
        setTimeout(() => {
            console.log("SET_TIMEOUT");
            const { boxes, colorPool } = this.state;
            const color = colorPool[generateRandomInteger(colorPool.length)];

            changeColor(color, boxes, colorPool);
        }, 1000);
*/
    });


    const changeColor = (color, boxes, colorPool) => {
        const randomBox = generateRandomInteger(boxes.length + 1);
        const randomBoxColor = boxes[randomBox];

        boxes[randomBox] = color;


        // find index of colorPool for color
        const theIndex = colorPool.indexOf(color);

        colorPool[theIndex] = randomBoxColor;
this.setState({ boxes, colorPool });
    };





    const initializeBoxes = () => {


        boxes = boxes.map(box => {
            const randomInteger = generateRandomInteger(colorPool.length);
            const randomColor = colorPool[randomInteger];

            //colorPool = colorPool.filter(currentColor => currentColor !== randomColor);

            return randomColor;
        });

//this.setState({ boxes, colorPool });
    }




    return (
        <BoxesContextProvider>
            <div className="App">
                <Header pageTitle="Random Boxes" />
                <Boxes />
            </div>
        </BoxesContextProvider>
    );
}

// RandomBoxes.contextType = BoxesContext;