import React, { Component } from 'react';
import Header from "../Header";
import { randomizeArray, generateRandomInteger } from "../helpers";
import "./memory.css";
import Boxes from "./Boxes";
import CustomButton from "../CustomButton";

// shuffle(npm install shuffle-array);
const data = require("./memory.json");

class MemoryGame extends Component {
    constructor(props) {
        super(props);
        const { colors } = data;
        const defaultState = {
            randomizedBoxesArr: [],
            // a set of each sets of colors[]
            colorsPoolArr: [...colors, ...colors], 
            currentSelection: [],
            message: "Welcome! Make two choices!"
        };
        this.state = { ...defaultState };
    };

    newGame = () => {
        const randomizedBoxesArr = randomizeArray(this.state.colorsPoolArr);
        this.setState({ ...this.defaultState, randomizedBoxesArr });
    };

    clearCurrentSelection = () => {
        const resetSelection = [];
        //this.setState({ currentSelection: [], "jake": "was here!!!" });

//        this.setState({currentSelection: []});
        //this.state.currentSelection.length = 0;

        this.setState({ currentSelection: {} });
    };

    componentDidMount() {
        this.newGame();
    };

    toggleBoxDisplay = (arrayGoesHere, index = 0) => {
    };

    compareMatch = (newSelection) => {
        return newSelection[0] === newSelection[1];
    };

    handleBoxSelection = (event) => {
        event.preventDefault();

        console.log("");

        const chosenBoxId = event.target.id;
        const { randomizedBoxesArr, currentSelection } = this.state;
        // const { color, isShown, isMatched, isDisabled } = randomizedBoxesArr[chosenBoxId];
        const color = randomizedBoxesArr[chosenBoxId].color;
        //const chosenBoxColor = randomizedBoxesArr[chosenBoxId].color;

        console.log("randomizedBoxesArr[chosenBoxId].color");
        console.log(randomizedBoxesArr[chosenBoxId].color);
        let newSelection = [];

    //setTimeout(() => {

    console.log("currentSelection.length");
    console.log(currentSelection.length);

    if(this.state.currentSelection.length === 0) {
        newSelection = [color];

        console.log("newSelection: ----> ");
        console.log(newSelection);
    }

    if(this.state.currentSelection.length === 1) {
        // if match, set isMatched
        // KEEP isDisabled (??)
        // KEEP isShown (??)
        const secondOne = currentSelection;
        console.log("secondOne ***********");
        console.log(secondOne);
        console.log("color: ", currentSelection[0]);

        // newSelection = [color];

//        newSelection = { currentSelection: [color, currentSelection[0]] };
        newSelection = [color, currentSelection[0]];

        //this.compareMatch(newSelection);
        

        if(this.compareMatch(newSelection)) {
            console.log("----> it's a match!!! <-----");
            // results__content
            this.setState({ message: "It's a match!" });
        } else {
            console.log("----> NOT a match!!! <-----");
            this.setState({ message: "Not a match!", currentSelection: ["orange", "blue"] });
        }

        this.clearCurrentSelection();
        console.log("UPDATED");
    }


    setTimeout(() => {
        console.log("EOF >>>>>>>");
        console.log("this.state.currentSelection");
        console.log(this.state.currentSelection);
        console.log(currentSelection);
        console.log("----#######------");
    }, 2000);






        const newRandomizedBoxesArr = randomizedBoxesArr.map((num, index) => {
            
            if(index.toString() === chosenBoxId) {
                num = {
                    color: num.color, 
                    isShown: true,
                    isDisabled: true,
                    isMatched: false
                };
            }            

            return num;
        });
        

        this.setState({
            randomizedBoxesArr: newRandomizedBoxesArr,
            currentSelection: newSelection
        });
    };

    render() {
        const { handleBoxSelection, newGame } = this;
        const pageTitle = "Memory Game";

        return (
            <div className="app">
                <Header pageTitle={ pageTitle } />

                <div className="main__content__container">
                    <h2 className="results__content">{ this.state.message }</h2>
    
                    <div className="main__content__boxes__container">
                        <Boxes
                            data={ this.state }
                            handleBoxSelection={ handleBoxSelection }
                        />
                    </div>

                    <CustomButton
                        id="reset"
                        title="Reset"
                        onClick={ newGame }
                        className="submit__button"
                        content="New Game"
                    />
                </div>
            </div>
        );
    }
}

export default MemoryGame;