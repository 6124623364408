import React, { Component } from 'react';
import Header from "../Header";

class GuessTheFlag extends Component {
    constructor(props) {
        super(props);
        console.clear();

        this.state = {
            fetchUrl: "https://restcountries.eu/rest/v2/all",
            data: []
        };
    }
    

    componentDidMount() {
    //    call the fetchUrl and get the data
    // this.setState
        this.loadData();

        console.log(this.state.data);

        this.processData();
    };


    loadData() {
        fetch(this.state.fetchUrl)
            .then(response => response.json())
            .then((data) => {


                this.processData(data);
                
                this.setState({
                    data
                });
            }).catch(err => {
                console.log(`Error in fetching data from server: ${err}`);
            });
    }

    processData(data) {
        console.log("processing data...");
        console.log(data);
    }

    render() {
        const pageTitle = "Guess the Flag"

        return (
            <div className="App">
                <Header pageTitle={pageTitle} />

                <div className="container-content">
                    Guess the Flags
                </div>
            </div>
        );
    }
}

export default GuessTheFlag;