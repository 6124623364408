import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';

import AppRouter from "./routers/AppRouter";

const RoutedApp = () => (
    <AppRouter>
        <App />
    </AppRouter>
);

ReactDOM.render(<RoutedApp />, document.getElementById('root'));