import React from 'react';
import './App.css';

/*
const config = require("./config.json");
const { projects, ideas } = config;
*/

function App() {
    return (
        <div>
            <header className="App-header">
                <p>
                    APPLICATION HEADER
                </p>
            </header>


            <div className="container-content">
                Here is the content container
            </div>

        </div>
    );
}

export default App;
