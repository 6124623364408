import React, { useContext } from "react";
import { BoxesContext } from "./boxes-context";



const Boxes = (props) => {
    const [state, dispatch] = useContext(BoxesContext);

/*     console.log("JAKE WAS HERE");
    console.log(this.props);

    console.log("JAKE WAS HERE"); */

    const theContent = state.boxes.map(function (box, index) {
        return (
            <button
                key={index}
                className="box__btn"
                style={{ backgroundColor: box }}
            >
                {box}
            </button>
        );
    });


    return (
        <div>
            { theContent }
        </div>
    );
};

export default Boxes;